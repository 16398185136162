import React from 'react'
import styled from 'styled-components'
import { Flex } from '@components/base/Flex'
import { AnimatedBackground, SectionFilter } from '../templates/index-page'
import { breakpoints } from '../styles/utils'

const HeaderContainer = styled(Flex)`
    height: 300px;
    width: 100%;
    z-index: -1;
    position: relative;
`

const HeaderBoxed = styled(Flex)`
    max-width: 1100px;
    width: 100%;
    color: ${({ theme }) => theme.color.accent};
    margin: auto;
    @media (max-width: ${breakpoints.md}px) {
        text-align: center;
    }
`

const BodyContainer = styled(Flex)`
    max-width: 1100px;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    min-height: 100vh;
    margin: -100px auto auto;
    z-index: 0;
`
const OverlappingSectionContainer = styled(Flex)`
    padding-bottom: 25px;
`

type SectionHeaderProps = {
    Header: React.ReactNode
    Body: React.ReactNode
}

export default function OverlappingSection({ Header, Body }: SectionHeaderProps) {
    return (
        <OverlappingSectionContainer>
            <HeaderContainer>
                <AnimatedBackground images={[]} />
                <SectionFilter />
                <HeaderBoxed>{Header}</HeaderBoxed>
            </HeaderContainer>
            <BodyContainer>{Body}</BodyContainer>
        </OverlappingSectionContainer>
    )
}
