import * as React from 'react'
import Layout from '@components/Layout/Layout'
import { Text } from '@components/base/Text'
import OverlappingSection from '@components/OverlappingSection'
import { Flex } from '@components/base/Flex'
import Gallery from '@components/Gallery/Gallery'
import { graphql, PageProps } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'

type Section = {
    title: string
    images: IGatsbyImageData[]
}

type DataProps = {
    markdownRemark: {
        frontmatter: {
            title: string
            sections?: Section[]
        }
    }
}
const GalleryPage = ({ data }: PageProps<DataProps>) => {
    const { t } = useTranslation()
    return (
        <Layout layoutType={'fullScreen'} occupySpace={false}>
            <OverlappingSection
                Header={
                    <Text bolded type={'h1'}>
                        {t(data.markdownRemark.frontmatter.title)}
                    </Text>
                }
                Body={
                    <Flex direction={'column'} flex={1}>
                        {data.markdownRemark.frontmatter.sections?.map((section) => (
                            <Flex key={section.title} padding={'medium'} direction={'column'}>
                                <Text type={'h2'}>{t(section.title)}</Text>
                                <Gallery images={section.images} />
                            </Flex>
                        ))}
                    </Flex>
                }
            />
        </Layout>
    )
}

export const pageQuery = graphql`
    query GalleryPageTemplate($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        markdownRemark(frontmatter: { templateKey: { eq: "gallery-page" } }) {
            frontmatter {
                title
                sections {
                    title
                    images {
                        childImageSharp {
                            gatsbyImageData(width: 1000, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                }
            }
        }
    }
`
export default GalleryPage
