import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '@components/Layout/Layout'
import { Text } from '@components/base/Text'
import styled from 'styled-components'
import { Flex } from '@components/base/Flex'
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '@components/base/Button'
import { breakpoints } from '../styles/utils'

const Hero = styled(Flex)<{ fullScreen?: boolean }>`
    width: 100%;
    height: ${({ fullScreen }) => (fullScreen ? '100vh' : 'calc(100vh - 70px)')};
    position: relative;
`

const SectionBackground = styled(Flex)<{ images?: number }>`
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;

    z-index: -2;

    ${({ images }) =>
        images &&
        images > 1 &&
        `
        
          div {
                animation: crossFade ${5 * images}s ease-in-out infinite alternate,
                    zoom-in-zoom-out 5s ease infinite alternate;
                position: absolute;
            }
        
        ${Array(images)
            .fill(0)
            .map((_, index) => {
                return `
            div:nth-of-type(${index + 1}) {
                animation-delay: ${(images - index) * 5}s;
            }
        `
            })}
        
        `};
    @keyframes zoom-in-zoom-out {
        0% {
            transform: scale(1, 1);
        }
        100% {
            transform: scale(1.05, 1.05);
        }
    }
    @keyframes crossFade {
        20% {
            opacity: 1;
        }
        40% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        70% {
            opacity: 1;
        }
    }
`
export const SectionFilter = styled(Flex)`
    position: absolute;

    background-color: #003157;
    opacity: 0.8;
    height: 100%;
    width: 100%;
    z-index: -1;
`

const TextContainer = styled(Flex)`
    margin-top: 50px;
    @media (max-width: ${breakpoints.md}px) {
        flex: 1;
    }
`
const Container = styled(Flex)<{ side: 'left' | 'right' }>`
    max-width: 400px;
    padding: 1em;
    align-self: ${({ side }) => (side === 'left' ? 'flex-start' : `flex-end`)};
    @media (max-width: ${breakpoints.md}px) {
        flex: 1;
    }
`

const SectionComponent = styled(Flex)<{ side: 'left' | 'right' }>`
    position: absolute;
    height: 100%;
    width: 50%;
    overflow-x: hidden;
    ${({ side }) => (side == 'left' ? `left: 0;` : `right: 0;`)}
    text-align: ${({ side }) => `${side}`};

    @media (max-width: ${breakpoints.md}px) {
        width: 100%;
    }
`

type Image = ImageDataLike

type Section = {
    heading: string
    description: string
    cta_link: string
    cta_text: string
    images?: Image[]
}

const AnimatedImage = styled(GatsbyImage)`
    position: absolute;
    width: 100%;
    height: 100%;
`

type AnimatedBackgroundProps = {
    images: Image[]
}
export const AnimatedBackground = ({ images }: AnimatedBackgroundProps) => {
    return (
        <SectionBackground images={images.length}>
            {images?.map((image, index) => {
                const imageData = getImage(image)
                return imageData && <AnimatedImage key={index} image={imageData} alt={'Promotional image'} />
            })}
        </SectionBackground>
    )
}

type DataProps = {
    markdownRemark: {
        frontmatter: {
            title: string
            subheading: string
            sections?: Section[]
            images?: Image[]
        }
    }
}
const IndexPage = ({ data }: PageProps<DataProps>) => {
    const { t } = useTranslation()
    const { title, subheading, sections, images } = data.markdownRemark.frontmatter
    return (
        <Layout occupySpace={false}>
            <Hero fullScreen alignItems={'center'} justifyContent={'center'} dir={'column'}>
                {images && <AnimatedBackground images={images} />}
                <SectionFilter />
                <Text type={'h1'} bolded centered color={'white'}>
                    {t(title)}
                </Text>
                <Text type={'h2'} centered color={'white'}>
                    {t(subheading)}
                </Text>
                <PrimaryButton href={'/contact'} width={100}>
                    {t('visit_us')}
                </PrimaryButton>
            </Hero>
            {sections?.map((section, index) => (
                <Hero>
                    {section.images && <AnimatedBackground images={section.images} />}
                    <SectionComponent side={index % 2 === 1 ? 'left' : 'right'}>
                        <SectionFilter />
                        <TextContainer>
                            <Container side={index % 2 === 1 ? 'left' : 'right'}>
                                <Text fontFamily={'ZCool'} type={'h1'} color={'white'}>
                                    {t(section.heading)}
                                </Text>
                                <Text fontFamily={'Montserrat'} type={'h3'} color={'white'}>
                                    {t(section.description)}
                                </Text>
                            </Container>
                        </TextContainer>
                    </SectionComponent>
                </Hero>
            ))}
        </Layout>
    )
}

export const pageQuery = graphql`
    query IndexPageTemplate($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            frontmatter {
                title
                subheading
                images {
                    childImageSharp {
                        gatsbyImageData(width: 1000, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                    }
                }
                sections {
                    heading
                    description
                    cta_link
                    cta_text
                    images {
                        childImageSharp {
                            gatsbyImageData(width: 1000, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                }
            }
        }
    }
`

export default IndexPage
