import * as React from 'react'
import { Flex } from '@components/base/Flex'
import styled from 'styled-components'
import { breakpoints } from '../../styles/utils'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { useMemo, useState } from 'react'
import IconButton from '@components/base/IconButton'

const GalleryEntry = styled(GatsbyImage)`
    max-height: 300px;
    max-width: 300px;
    text-align: center;
    border-radius: 5px;
    justify-content: center;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    cursor: pointer;
`
const GalleryContainer = styled(Flex)`
    gap: 20px;
    padding-top: 20px;
    flex-wrap: wrap;
    justify-content: space-around;

    @media (max-width: ${breakpoints.md}px) {
        justify-content: center;
    }
`

const GalleryFullImage = styled(GalleryEntry)`
    margin-top: 70px;
    max-width: 75%;
    max-height: 75%;
    @media (max-width: ${breakpoints.md}px) {
        max-width: 100%;
        max-height: 100%;
    }
`
const GalleryControls = styled(Flex)`
    width: 75%;
`

const Lightbox = styled(Flex)`
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    z-index: 2;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
`

type GalleryProps = {
    images: IGatsbyImageData[]
}

export default ({ images }: GalleryProps) => {
    console.log(images)
    const [activeImage, setActiveImage] = useState<number | undefined>(undefined)
    const canPrev = useMemo(() => activeImage != undefined && activeImage > 0, [activeImage, images])
    const canNext = useMemo(() => activeImage != undefined && activeImage < images.length - 1, [activeImage, images])

    const nextImage = () => {
        if (canNext && activeImage !== undefined) setActiveImage(activeImage + 1)
    }

    const prevImage = () => {
        if (canPrev && activeImage !== undefined) setActiveImage(activeImage - 1)
    }
    const currentImage = useMemo(
        () => activeImage !== undefined && getImage(images[activeImage]),
        [activeImage, images]
    )

    const closeLightbox = () => {
        setActiveImage(undefined)
    }
    return (
        <>
            {currentImage && (
                <Lightbox justifyContent={'center'} alignItems={'center'}>
                    <GalleryFullImage image={currentImage} objectFit={'cover'} alt={'Image'} />
                    <GalleryControls direction={'row'} justifyContent={'space-between'}>
                        <IconButton disabled={!canPrev} name={'mdiArrowLeft'} onClick={prevImage} color={'black'} />
                        <IconButton name={'mdiClose'} onClick={closeLightbox} color={'black'} />
                        <IconButton disabled={!canNext} name={'mdiArrowRight'} onClick={nextImage} color={'black'} />
                    </GalleryControls>
                </Lightbox>
            )}
            <GalleryContainer direction={'row'}>
                {images.map((image, index) => {
                    const imageData = getImage(image)
                    return (
                        imageData && (
                            <GalleryEntry
                                onClick={() => setActiveImage(index)}
                                image={imageData}
                                objectFit={'cover'}
                                alt={'Image'}
                            />
                        )
                    )
                })}
            </GalleryContainer>
        </>
    )
}
